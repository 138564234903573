<template>
    <div class="footer">
        <div class="p">公安备案号1010502030143&nbsp;&nbsp;渝ICP备19004658号&nbsp;&nbsp;渝网文[2020]&nbsp;1039-165号&nbsp;&nbsp;经营性网站备案信息&nbsp;&nbsp;重庆互联网违法和不良信息举报中心</div>
        <div class="p">网络110报警服务&nbsp;&nbsp;中国互联网举报中心&nbsp;&nbsp;家长监护&nbsp;&nbsp;Chrome商店下载&nbsp;&nbsp;◎1999-2021重庆科技学院&nbsp;&nbsp;版权与免责声明&nbsp;&nbsp;版权申诉</div>
        <div class="p">出版物许可证&nbsp;&nbsp;营业执照</div>
    </div>
</template>

<script>
    export default {
        name: "Footer"
    }
</script>

<style lang="less" scoped>
    .footer {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
    }

    .p {
        color: #A9B5CA;
        font-size: 12px;
    }
</style>
